export const WhoFollowsUser = [
    {
        id: 3,
        username: "Businessweek",
        fullName: "BW",
        avatar: "https://pbs.twimg.com/profile_images/1631724004519157794/JsW1BXCd_bigger.jpg"
    },
    {
        id: 4,
        username: "rezalet iyi şeyler",
        fullName: "rezaletiyisey",
        avatar: "https://pbs.twimg.com/profile_images/1193266536905166848/j596Vxn8_bigger.jpg"
    },
    {
        id: 5,
        username: "Siyasi tweetler",
        fullName: "SilivrilikTweet",
        avatar: "https://pbs.twimg.com/profile_images/1433175757237493762/wtJREawh_bigger.jpg"
    },
]
export const Activity = [
    {
        id: 1,
        activityName: "Türkiye İş Bankası Hiring Week",
        name: "Türkiye İş Bankası",
        description:"Chance To Join KPMG",
        date:"23 Şubat",
        img: "https://coderspace-storage-prod.s3.eu-central-1.amazonaws.com/media/cache/08/e6/08e6511cb7cbee3cd5c09f5c9f20f9a1.jpg"
    },
    {
        id: 2,
        activityName: "Fibabanka Fintern Future Talent Program 2024",
        name: "Fibabanka",
        description:"Sign up now and be part of the transformative “Women in AI” Hackathon!",
        date:"23 Şubat",
        img: "https://coderspace-storage-prod.s3.eu-central-1.amazonaws.com/media/cache/6d/56/6d567ef3b56f184c333ab31ba7034b06.jpg"
    },
    {
        id: 3,
        activityName: "Cyber Security Day",
        name: "Codespace",
        description:"Kariyer yolculuğuna Softtech'le başla!",
        date:"23 Şubat",
        img: "https://coderspace-storage-prod.s3.eu-central-1.amazonaws.com/media/cache/ea/38/ea38b10c01721d501e36f2856887e580.jpg"
    },
    {
        id: 4,
        activityName: "Travel to Future Program",
        name: "Amedeus",
        description:"Hemen Başvur, Rotanı Yıldızlara Çevir!",
        date:"23 Şubat",
        img: "https://coderspace-storage-prod.s3.eu-central-1.amazonaws.com/media/cache/30/a9/30a939f5bf4100992777c2602f0e227a.jpg"
    },
    {
        id: 5,
        activityName: "Areon Hackathon",
        name: "Areon",
        description:"Bilişim Sektörünün En İlham Verici Kadınlarının Hikayelerini Dinle, Kariyerine Yön Ver!",
        date:"23 Şubat",
        img: "https://coderspace-storage-prod.s3.eu-central-1.amazonaws.com/media/cache/07/51/07517856b95e56b11c4372e04d141bb6.jpg"
    },
]
export const languages = [
    {
        id: 1,
        language: "Java",
        section: "BackEnd Developer",
    },
    {
        id: 1,
        language: "C#",
        section: "BackEnd Developer",
    },
    {
        id: 1,
        language: "C++",
        section: "BackEnd Developer",
    },
    {
        id: 1,
        language: "PHP",
        section: "BackEnd Developer",
    },
    {
        id: 2,
        language: "Vue",
        section: "Front-End Developer",
    },
    {
        id: 3,
        language: "TypeScript",
        section: "Front-End Developer",
    },
    {
        id: 2,
        language: "Angular",
        section: "Front-End Developer",
    },
    {
        id: 3,
        language: "JavaScript",
        section: "Front-End Developer",
    },
    {
        id: 2,
        language: "Data Analyst",
        section: "Data",
    },
    {
        id: 3,
        language: "Data Engineer",
        section: "Data",
    },
    {
        id: 2,
        language: "Unity",
        section: "Game Developer",
    },
    {
        id: 3,
        language: "Unreal Engine",
        section: "Game Developer",
    },
]