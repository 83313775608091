import { useState } from "react";
import Disabled from "./Disabled";

export default function ExtraInformation(){
    const [disabled, setDisabled] = useState([""]);

    const handleAddInputDisabled = (e) => {
        e.preventDefault();
        setDisabled(prevTechnology => [...prevTechnology, '']);
    };
    return(
        <form>
            <div className="p-12">
                <div className="flex justify-between items-center">
                    <div className="mt-10 mb-3 text-[23px]">Engel Bilgileri</div>
                    <button className="text-[23px] text-[color:var(--color-primary)] font-bold" onClick={handleAddInputDisabled}>Yeni Ekle + </button>
                </div>
                <div>
                    {
                        disabled.map((input, index) => (
                            <Disabled key={index} index={index} disabled={disabled} setDisabled={setDisabled} />
                        ))
                    }
                </div>
            </div>
        </form>
    )
}