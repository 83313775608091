import Button from "../../../components/button";

export default function PartipicationDetail() {
    return (
        <aside className="w-[375px] max-h-screen min-h-screen px-2 flex flex-col sticky top-0">
            <div className="text-[24px] m-8">Başvuru Detayları</div>

            <div className="h-px bg-[color:var(--background-third)]  w-full" />

            <div className="w-[87%] mx-auto h-auto">
                <div className="py-4 flex text-left w-full transition-colors items-center block group">
                    <svg viewBox="0 0 24 24" width={24} height={24} className="mr-2">
                        <path
                            fill="currentColor"
                            d="M19,4H17V3a1,1,0,0,0-2,0V4H9V3A1,1,0,0,0,7,3V4H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm1,15a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12H20Zm0-9H4V7A1,1,0,0,1,5,6H7V7A1,1,0,0,0,9,7V6h6V7a1,1,0,0,0,2,0V6h2a1,1,0,0,1,1,1Z"
                        />
                    </svg>
                    <div className="mx-3 flex-1">
                        <div className="text-[color:var(--color-base-secondary)] text-[16px]">SON BAŞVURU TARİHİ</div>
                        <div className="text-[18px] my-2">23 Şubat 2024 Cuma - 23:59</div>
                    </div>

                </div>
                <div className="h-px bg-[color:var(--background-third)]  w-full" />

                <div className="py-4 flex text-left w-full transition-colors items-center block group">
                    <svg viewBox="0 0 24 24" width={24} height={24} className="mr-2">
                        <path
                            fill="currentColor"
                            d="M12 7V12H15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                        />
                    </svg>
                    <div className="mx-3 flex-1">
                        <div className="text-[color:var(--color-base-secondary)] text-[16px]">BAŞLANGIÇ/BİTİŞ TARİHLERİ</div>
                        <div className="text-[18px] my-2">7 Mart 2024 Perşembe - 09:00</div>
                        <div className="text-[18px] my-2">3 Nisan 2024 Çarşamba - 23:59</div>
                    </div>

                </div>
                <div className="h-px bg-[color:var(--background-third)]  w-full" />

                <div className="py-4 flex text-left w-full transition-colors items-center block group">
                    <svg viewBox="0 0 24 24" width={24} height={24} className="mr-2">
                        <path
                            fill="currentColor"
                            d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z"
                        />
                    </svg>
                    <div className="mx-3 flex-1">
                        <div className="text-[color:var(--color-base-secondary)] text-[16px]">KONUM</div>
                        <div className="text-[18px] my-2">Online</div>
                    </div>
                </div>
                <div className="h-px bg-[color:var(--background-third)]  w-full" />

                <div className="py-4 flex text-left w-full transition-colors items-center block group">
                    <svg viewBox="0 0 24 24" width={24} height={24} className="mr-2">
                        <path
                            fill="currentColor"
                            d="M16,4H2A2.006,2.006,0,0,0,0,6V24H18V6A2.006,2.006,0,0,0,16,4ZM8,22V18h2v4Zm8,0H12V17a.945.945,0,0,0-1-1H7a.945.945,0,0,0-1,1v5H2V6H16ZM8,10H6V8H8Zm4,0H10V8h2ZM8,14H6V12H8Zm4,0H10V12h2Z"
                        />
                    </svg>
                    <div className="mx-3 flex-1">
                        <div className="text-[color:var(--color-base-secondary)] text-[16px]">ŞİRKET</div>
                        <div className="text-[18px] my-2">KPMG</div>
                    </div>
                </div>

                <Button size="normal" className="w-full">{`Hemen Başvur > `}</Button>
            </div>
        </aside>
    );
}