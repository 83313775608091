import React from 'react';
import { useDispatch } from 'react-redux';
import { selectLanguage } from '../../../store/language/actions';


const Checkbox = ({ language }) => {

  const dispatch = useDispatch();

  const handleCheckboxChange = () => {
    dispatch(selectLanguage(language));
  };

  return (

    <div className='mb-3'>
      <div className="items-center me-4">
        <input id="inline-checkbox" className='w-6 h-6 rounded transition-colors' type="checkbox" onChange={handleCheckboxChange} />
        <label for="inline-checkbox" class="ms-2 text-lg font-medium ">{language.section}({language.language})</label>
      </div>
    </div>
  );
};

export default Checkbox;
/**
 * <div>
      <div>BackEnd Developer</div>
      {
        if()
      }
      <div>
        <input type="checkbox" onChange={handleCheckboxChange} />
        <div>{language.section}({language.language})</div>
      </div>;
    </div>



    {(() => {
          switch (language.section) {
            case 'BackEnd Developer':
              return <div>
                <input type="checkbox" onChange={handleCheckboxChange} />
                <div>{language.section}({language.language})</div>
                
              </div>;

            default:
              return null;
          }
        })()}
 */