import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import 'semantic-ui-css/semantic.min.css'
//import 'react-toastify/dist/ReactToastify.min.css';
//import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/tailwind.css";
import { Provider } from 'react-redux';
import store from './store';





const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}><App /></Provider>
  

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
