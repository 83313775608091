import { Link, NavLink } from "react-router-dom";
import { Activity } from "../../../mock";
import classNames from "classnames";

export default function Event(){
    return(
        <div className="mt-5">
            {
                Activity.map(item=>(
                    <NavLink to="#"   className="py-3 flex text-left w-full transition-colors items-center block group">
                        <img src={item.img} className="w-30 h-30 rounded-full" />
                        <div className="mx-3 flex-1">
                            <h6 className="text-[22px]">{item.activityName}</h6>
                            <div className="text-[color:var(--color-base-secondary)] text-[16px] my-2">{item.name}</div>
                            <div className="text-[color:var(--color-primary)] font-bold text-[16px] my-2">Hemen Başvur</div>
                        </div>
                    </NavLink>
                ))
            }
        </div>
    )
}