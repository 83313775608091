import { useState } from "react";
import UniversityInformation from "./universityInformation";
import WorkInformation from "./workInformation";
import Button from "../../components/button";

export default function University() {

    const [university, setUniversity] = useState([""]);
    const handleAddInputUniversity = (e) => {
        e.preventDefault();
        setUniversity([...university, '']);
    };

    const [works, setWorks] = useState([""]);
    const handleAddInputWorks = (e) => {
        e.preventDefault();
        setWorks([...works, '']);
    };

    return (
        <form>
            <div className="p-12">
                <div className="pr-4 text-[20px] font-bold my-4">
                    Deneyim & Üniversite
                </div>
                <div className="h-px bg-[color:var(--background-third)]  w-[89%]" />
                <div className="flex justify-between items-center">
                    <div className="mt-10 mb-3 text-[23px]">Üniversite Bilgilerin</div>
                    <button className="text-[23px] text-[color:var(--color-primary)] font-bold" onClick={handleAddInputUniversity}>Yeni Ekle + </button>
                </div>
                <div>
                    {
                        university.map((input, index) => (
                            <UniversityInformation index={index} universityitem={university} setUniversity={setUniversity} />
                        ))
                    }
                </div>
                <div className="flex justify-between items-center">
                    <div className="mt-10 mb-3 text-[23px]">İş Hayatı Bilgilerin</div>
                    <button className="text-[23px] text-[color:var(--color-primary)] font-bold" onClick={handleAddInputWorks}>Yeni Ekle + </button>
                </div>
                <div>
                    {
                        works.map((input, index) => (
                            <WorkInformation index={index} works={works} setWorks={setWorks} />
                        ))
                    }
                </div>
            </div>
            <div className="justify-items-stretch grid">
                <div className="m-10 justify-self-end w-[390px]">
                    <Button size="normal" className="w-full">{`Kaydet > `}</Button>
                </div>
            </div>
        </form>


    )
}