import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { mainMenu } from "../../../../utils/consts";

import New from "./new";
import { useAccount } from "../../../../store/auth/hooks";
import More from "./more";
export default function Menu() {
    const account=useAccount()
    return (
        <nav className="mt-0.5 mb-1" key={account}>
            {
                mainMenu.map((menu, index) => (
                    <NavLink to={typeof menu.path==="function" ?menu.path():menu.path} className="py-[3px] block group">
                        {({ isActive }) => (
                            <div className={classNames("p-3 rounded-full transition-colors inline-flex items-center gap-3 group-hover:bg-[color:var(--background-third)]", { "font-bold": isActive })}>
                                <div className="w-[26.25px] h-[26.25px] relative">
                                    {menu?.notification && (
                                        <span className="w-[18px] h-[18px] rounded-full bg-[color:var(--color-primary)] border border-[color:var(--background-primary)] text-[color:var(--background-primary)] absolute -top-1.5 -right-1 flex items-center justify-center text-[11px]">
                                            {menu?.notification}
                                        </span>
                                    )}
                                {!isActive && menu.icon.passive}
                                {isActive && menu.icon.Active}
                                </div>
                                
                                <div className="pr-4 text-lg">
                                    {menu.title}
                                </div>
                            </div>
                        )}
                    </NavLink>
                ))
            }
        </nav>

    );
}