import { useSelector } from "react-redux";
import Button from "../../components/button";
import CheckBox from "../../components/inputs/checkBox";
import ClassicInputs from "../../components/inputs/classicInputs";
import IconInputs from "../../components/inputs/iconInputs";
import Checkbox from "./checkBox";
import { languages } from "../../mock";

export default function CareerInfo() {
    return (
        <form>
            <div className="p-12">
                <div className="pr-4 text-[20px] font-bold my-4">
                    Kariyer Bilgileri
                </div>
                <div className="h-px bg-[color:var(--background-third)]  w-[89%]" />

                <div className="mt-10 mb-4 text-[20px]">Hangi Tür İşlerle ilgileniyorsun ?</div>
                <div className="flex mb-20">
                    <CheckBox text="Full Time" />
                    <CheckBox text="Part Time" />
                    <CheckBox text="Freelance" />
                    <CheckBox text="Staj" />
                </div>

                <div className="mt-10 mb-4 text-[20px]">Hangi Tür Çalışma Şekillerini tercih edersin ?</div>
                <div className="flex mb-20">
                    <CheckBox text="Uzaktan" />
                    <CheckBox text="Hibrit" />
                    <CheckBox text="%100 Ofis" />
                </div>

                <div className="mt-10 mb-4 text-[20px]">Aylık net maaş beklentin ?</div>
                <div className=" mb-20 w-[400px]">
                    <IconInputs className="w-full" svg="m4.488 0h2.411v6.2c2.434-.88 4.89-1.805 7.341-2.693v1.993c-2.454.88-4.889 1.775-7.341 2.655v1.027c2.451-.888 4.907-1.813 7.341-2.693v1.978c-2.433.905-4.894 1.78-7.341 2.67v10.277c3.42-.449 6.235-2.662 7.53-5.681l.024-.062c.471-1.087.746-2.352.746-3.682 0-.003 0-.005 0-.008h2.502v.38c-.058 1.825-.512 3.53-1.279 5.052l.032-.069c-.702 1.379-1.601 2.553-2.676 3.536l-.009.008c-2.106 1.933-4.926 3.117-8.023 3.117-.443 0-.88-.024-1.311-.071l.053.005v-11.92c-1.506.53-2.99 1.083-4.488 1.62v-1.92c1.501-.548 3.006-1.091 4.488-1.658v-1.019c-1.493.51-2.99 1.082-4.488 1.613v-1.91c1.491-.56 3.009-1.094 4.488-1.666z" />
                </div>

                <div className="mt-10 mb-1 text-[20px]">Tam zamanlı yazılım&teknoloji kariyerine hangi tarihte başladın ?</div>
                <div className="mb-4 text-[17px] text-[color:var(--background-modal)] ">Ön Lisans / Lisans eğitimine devam ediyorsan boş bırakabilirsin.</div>
                <div className=" mb-20 w-[400px]">
                    <ClassicInputs placeholder="AA-YYYY" size="normal" />
                </div>


                <div>
                    <h1 className="my-10 text-[20px]">Hangi pozisyon senin için en uygun ?</h1>
                    {languages.map((language) => (
                        <Checkbox key={language.id} language={language} />
                    ))}
                </div>


                <div className="mt-10 mb-4 text-[20px]">Hangi şehir/Ülkelerde çalışmak istersin ?</div>
                <div className="flex mb-20">
                    <CheckBox text="İstanbul" />
                    <CheckBox text="Ankara" />
                    <CheckBox text="İzmir" />
                    <CheckBox text="Türkiye (Diğer)" />
                    <CheckBox text="Avrupa" />
                    <CheckBox text="ABD" />
                </div>

                <div className="mt-10 mb-4 text-[20px]">Hangi Tür Çalışma Şekillerini tercih edersin ?</div>
                <div className="flex mb-10">
                    <CheckBox text="AB ülkeleri için çalışma iznim var." />
                    <CheckBox text="ABD için çalışma iznim var." />
                </div>

                <div className="justify-items-stretch grid">
                    <div className="m-10 justify-self-end w-[390px]">
                        <Button size="normal" className="w-full">{`Kaydet > `}</Button>
                    </div>
                </div>
            </div>
        </form>
    );
}