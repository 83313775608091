import { NavLink } from "react-router-dom";
import Button from "../../components/button";
import IconInputs from "../../components/inputs/iconInputs";
import { Activity } from "../../mock";

export default function Activities() {
    return (
        <div>
            <div className="flex justify-between items-center my-4">
                <div className="mt-10 mb-3 mr-[500px] text-[35px] font-bold">Etkinlikler</div>
                <div className="flex-1 mt-3">
                    <IconInputs className="w-full" placeholder="Ara..." svg="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9 11.5C9 10.1193 10.1193 9 11.5 9C12.8807 9 14 10.1193 14 11.5C14 12.8807 12.8807 14 11.5 14C10.1193 14 9 12.8807 9 11.5ZM11.5 7C9.01472 7 7 9.01472 7 11.5C7 13.9853 9.01472 16 11.5 16C12.3805 16 13.202 15.7471 13.8957 15.31L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L15.31 13.8957C15.7471 13.202 16 12.3805 16 11.5C16 9.01472 13.9853 7 11.5 7Z" />
                </div>
            </div>
            <div className="grid grid-cols-3 mt-3 gap-10">
                {
                    Activity.map(item => (
                        <div className="text-center max-w-sm border-2 border-[color:var(--background-third)] rounded-lg shadow ">
                            <div className="mx-3">
                                <div className="mt-3 mb-12">
                                    <img src={item.img} className="h-[200px] w-[300px] w-full rounded-t-lg" />
                                </div>
                                <div className="h-12">
                                    <h6 className="text-[22px]">{item.activityName}</h6>
                                </div>                       
                                <div className="text-[color:var(--color-base-secondary)] text-[16px] my-12 h-12">{item.description}</div>
                                <div className="text-[color:var(--color-base-secondary)] text-[16px] mt-12 h-8">Son Başvuru</div>
                                <div className=" text-[16px] mb-12 h-8">{item.date}</div>
                                <div className="h-15 my-6">
                                    <NavLink to="/testler/etkinlikler/etkinlik-detayi">
                                        <Button size="normal" className="w-full">{`Detaylı Bilgi > `}</Button>
                                    </NavLink>
                                    
                                </div>
                                
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>

    );
}
