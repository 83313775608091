import { Link } from "react-router-dom";
import Button from "../../components/button/index"
import classNames from "classnames";
import { useAppearance } from "../../store/appearance/hooks";
import { setBackGroundColor, setColor, setFontSize } from "../../store/appearance/action";
import { colors, fontSize } from "../../utils/consts";
export default function AppearanceModal({ close }) {
    const appearance = useAppearance();
    return (
        <div className="w-[600px]">
            <h3 className="mt-8 mb-3 text-[1.438rem] font-extrabold text-center leading-7">
                Görünümü Özelleştir
            </h3>
            <div className="p-8 pt-0 ">
                <div className="mx-8 mb-4">

                </div>

                <div className="grid gap-3">
                    <section>
                        <h6 className="text-[color:var(--color-base-secondary)] mb-1 leading-5 text-[13px] font-bold">Yazı Tipi</h6>
                        <div className="bg-[color:var(--background-secondary)] p-4 rounded-2xl flex items-center gap-5">
                            <div className="text-[0.813rem]">Aa</div>
                            <div className="h-1 bg-[color:var(--color-primary)] flex-1 rounded-full flex justify-between">
                                {fontSize.map(fs => (
                                    <button
                                        onClick={() => {
                                            setFontSize(fs)
                                        }}
                                        className="before:absolute before:inset-0 before:rounded-full before:hover:bg-[color:var(--color-primary)] before:opacity-10 w-8 h-8 rounded-full flex items-center justify-center relative -top-3.5 first:-ml-2 last:-mr-2">
                                        <div className={classNames("w-3 h-3 rounded-full bg-[color:var(--color-primary)]", {
                                            "w-4 h-4": appearance.fontSize === fs
                                        })} />
                                    </button>
                                ))}
                            </div>
                            <div className="text-[1.25rem]">Aa</div>
                        </div>
                    </section>

                    <section>
                        <h6 className="text-[color:var(--color-base-secondary)] mb-1 leading-5 text-[13px] font-bold">Renk</h6>
                        <div className="bg-[color:var(--background-secondary)] py-2 rounded-2xl flex justify-around items-center">
                            {colors.map((color, index) => (
                                <button
                                    onClick={() => {
                                        setColor({
                                            ...appearance.color,
                                            primary: color
                                        })
                                    }}
                                    style={{ "--bg": color }} className="w-[40px] h-[40px] rounded-full bg-[color:var(--bg)] flex items-center justify-center">
                                    {color === appearance.color.primary && (
                                        <svg viewBox="0 0 24 24" width={25}>
                                            <path
                                                fill="currentColor"
                                                d="M9.64 18.952l-5.55-4.861 1.317-1.504 3.951 3.459 8.459-10.948L19.4 6.32 9.64 18.952z"
                                            />
                                        </svg>
                                    )}

                                </button>
                            ))}
                        </div>
                    </section>

                    <section>
                        <h6 className="text-[color:var(--color-base-secondary)] mb-1 leading-5 text-[13px]">Arka Plan</h6>
                        <div className="gap-1 py-2 px-4 mb-3 grid grid-cols-3 bg-[color:var(--background-secondary)] rounded-2xl">
                            <button
                                onClick={() => {
                                    setColor({
                                        ...appearance.color,
                                        base: "#0f1419",
                                        baseSecondary: "#8b98a5"
                                    })
                                    setBackGroundColor({
                                        name: "light",
                                        primary: "#fff",
                                        primaryAlpha: "#ffffffa6",
                                        secondary: "#f7f9f9",
                                        third: "#eff3f4",
                                        modal: "#00000066"
                                    })
                                }}
                                className={classNames("h-[62px] pr-3 pl-2 bg-white text-black border border-white/10 font-bold rounded group flex items-center gap-1.5", {
                                    "border-[color:var(--color-primary)] !border-2": appearance.backGroundColor.name === "light"
                                })}>
                                <div className="w-[40px] h-[40px] rounded-full flex-shrink-0 group-hover:bg-white/10 flex items-center justify-center">
                                    <div className={classNames("w-[20px] h-[20px] rounded-full border-2 border-[#3e4144]", {
                                        "border-[color:var(--color-primary)] bg-[color:var(--color-primary)] text-white": appearance.backGroundColor.name === "light"
                                    })}>
                                        {
                                            appearance.backGroundColor.name === "light" && (
                                                <svg viewBox="0 0 24 24">
                                                    <path
                                                        fill="currentColor"
                                                        d="M9.64 18.952l-5.55-4.861 1.317-1.504 3.951 3.459 8.459-10.948L19.4 6.32 9.64 18.952z"
                                                    />
                                                </svg>
                                            )
                                        }

                                    </div>
                                </div>
                                Varsayılan
                            </button>
                            <button
                                onClick={() => {
                                    setColor({
                                        ...appearance.color,
                                        base: "#f7f9f9",
                                        baseSecondary: "#8b98a5",
                                    })
                                    setBackGroundColor({
                                        name: "dark",
                                        primary: "#15202b",
                                        primaryAlpha: "#15202ba6",
                                        secondary: "#1e2732",
                                        third: "#263340",
                                        modal: "#5b708366"
                                    })
                                }}
                                className={classNames("h-[62px] pr-3 pl-2 bg-[#15202b] text-[#f7f9f9] border border-white/10 font-bold rounded group flex items-center gap-1.5", {
                                    "border-[color:var(--color-primary)] !border-2": appearance.backGroundColor.name === "dark"
                                })}>
                                <div className="w-[40px] h-[40px] rounded-full flex-shrink-0 group-hover:bg-white/10 flex items-center justify-center">
                                    <div className={classNames("w-[20px] h-[20px] rounded-full border-2 border-[#3e4144]", {
                                        "border-[color:var(--color-primary)] bg-[color:var(--color-primary)] text-white": appearance.backGroundColor.name === "dark"
                                    })}>
                                        {
                                            appearance.backGroundColor.name === "dark" && (
                                                <svg viewBox="0 0 24 24">
                                                    <path
                                                        fill="currentColor"
                                                        d="M9.64 18.952l-5.55-4.861 1.317-1.504 3.951 3.459 8.459-10.948L19.4 6.32 9.64 18.952z"
                                                    />
                                                </svg>
                                            )
                                        }

                                    </div>
                                </div>
                                Loş
                            </button>
                            <button
                                onClick={() => {
                                    setColor({
                                        ...appearance.color,
                                        base: "#e7e9ea",
                                        baseSecondary: "#71767b",
                                    })
                                    setBackGroundColor({
                                        name: "darker",
                                        primary: "#000",
                                        primaryAlpha: "#000000a6",
                                        secondary: "#16181c",
                                        third: "#273340",
                                        modal: "#5b708366"

                                    })
                                }}
                                className={classNames("h-[62px] pr-3 pl-2 bg-black text-white border border-white/10 font-bold rounded group flex items-center gap-1.5", {
                                    "border-[color:var(--color-primary)] !border-2": appearance.backGroundColor.name === "darker"
                                })}>
                                <div className="w-[40px] h-[40px] rounded-full flex-shrink-0 group-hover:bg-white/10 flex items-center justify-center">
                                    <div className={classNames("w-[20px] h-[20px] rounded-full border-2 border-[#3e4144]", {
                                        "border-[color:var(--color-primary)] bg-[color:var(--color-primary)] text-white": appearance.backGroundColor.name === "darker"
                                    })}>
                                        {
                                            appearance.backGroundColor.name === "darker" && (
                                                <svg viewBox="0 0 24 24">
                                                    <path
                                                        fill="currentColor"
                                                        d="M9.64 18.952l-5.55-4.861 1.317-1.504 3.951 3.459 8.459-10.948L19.4 6.32 9.64 18.952z"
                                                    />
                                                </svg>
                                            )
                                        }

                                    </div>
                                </div>
                                <div className="truncate">Işıklar Kapalı</div>

                            </button>
                        </div>
                    </section>
                </div>

                <div className="flex items-center justify-center pt-4">
                    <Button onClick={close}>Bitti</Button>
                </div>
            </div>
        </div>
    )
}

