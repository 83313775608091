import { useNavigate } from "react-router-dom";
import { useAccount} from "../../../../../store/auth/hooks"



export default function More() {
    const currentAccount = useAccount()
    const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/');
  };
    return (
        <div>           
            <button onClick={handleLogin} className="py-3 px-4 text-left transition-colors hover:bg-[#eff3f41a] w-full  font-bold text-[15px]">{`${currentAccount.fullName} Hesabından Çıkış Yap`}</button>
        </div>

    )
}