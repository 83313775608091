import Button from "../../../components/button";
import ClassicInputs from "../../../components/inputs/classicInputs";
import SelectInputs from "../../../components/inputs/selectİnputs";
import { department, university } from "../../../utils/consts";

export default function UniversityInformation({ universityitem, setUniversity, index }) {
    const handleRemoveInput = (index,e) => {
        e.preventDefault();
        const newList = universityitem.filter((_, i) => i !== index);
        setUniversity(newList);
    };
    return (
        <div key={index} className="grid grid-cols-2 mt-3 gap-10 mb-20">
            <div>
                <SelectInputs size="normmal">
                    <option requ selected>Üniversite</option>
                    {
                        university.map(item => (
                            <option>{item.Adı}</option>
                        ))
                    }
                </SelectInputs>
            </div>
            <div>
                <div className="grid grid-cols-2 gap-10">
                    <SelectInputs size="normmal">
                        <option selected>Bölüm</option>
                        {
                            department.map(item => (
                                <option>{item}</option>
                            ))
                        }
                    </SelectInputs>
                    <SelectInputs size="normmal">
                        <option selected>Derece</option>
                        <option>Ön Lisans</option>
                        <option>Lisans</option>
                        <option>Yüksek Lisans</option>
                        <option>Doktora</option>
                    </SelectInputs>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-10">
                <ClassicInputs placeholder="Başlangıç tarihi" size="normal" />
                <ClassicInputs placeholder="Bitiş Tarihi" size="normal" />
            </div>
            <div>
                <Button onClick={(e) => handleRemoveInput(index,e)} className="transition-colors" size="normal" variant="white-outline">Sil</Button>
            </div>
        </div>
    )
}