import Button from "../../../components/button";
import ClassicInputs from "../../../components/inputs/classicInputs";
import SelectInputs from "../../../components/inputs/selectİnputs";

export default function WorkInformation({ works, setWorks, index }) {
    const handleRemoveInput = (index,e) => {
        e.preventDefault();
        const newList = works.filter((_, i) => i !== index);
        setWorks(newList);
    };
    return (
        <div key={index} className="grid grid-cols-2 mt-3 gap-10 mb-20">
            <div>
            <ClassicInputs placeholder="Şirket & Proje"  size="normal" />
            </div>
            <div>
                <div className="grid grid-cols-2 gap-10">
                <ClassicInputs placeholder="Pozisyon"  size="normal" />
                    <SelectInputs size="normmal">
                        <option selected>Statü</option>
                        <option>Full Time</option>
                        <option>Part Time</option>
                        <option>Remote</option>
                        <option>Staj</option>
                    </SelectInputs>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-10">
                <ClassicInputs placeholder="Başlangıç tarihi" size="normal" />
                <ClassicInputs placeholder="Bitiş Tarihi"  size="normal" />
            </div>
            <div>
                <Button onClick={(e) => handleRemoveInput(index,e)} className="transition-colors" size="normal" variant="white-outline">Sil</Button>
            </div>
        </div>
    )
}