import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import ClassicInputs from "../../components/inputs/classicInputs";

export default function Settings() {
    const navigate = useNavigate();

  const LogOut = () => {
    navigate('/');
  };
    return (
        <div className="p-12">
            <div className="pr-4 text-[20px] font-bold my-4 flex justify-between items-center">
                Yetenek Bilgileri
                <button className="text-[18px] text-[color:red]" onClick={LogOut}>{`Çıkış Yap > `}</button>
            </div>
            <div className="h-px bg-[color:var(--background-third)]  w-[97%]" />
            <div className="pr-4 text-[20px] font-bold my-5 flex justify-between items-center">
                Şifre Değiştirme
            </div>
            <form className="">
                <div className="">
                    <div className=" mb-12 w-[400px]">
                        <ClassicInputs placeholder="Mevcut Parola" size="normal" />
                    </div>
                    <div className=" mb-12 w-[400px]">
                        <ClassicInputs placeholder="Yeni Parola" size="normal" />
                    </div>
                    <div className=" mb-12 w-[400px]">
                        <ClassicInputs placeholder="Yeni Parola (Tekrar)" size="normal" />
                    </div>
                    <Button size="normal" className="w-[400px] rounded-[8px]">{`Değiştir > `}</Button>
                </div>
            </form>

            <div className="pr-4 text-[20px] font-bold mt-28 mb-4">Hesap Silme </div>
            <button className="text-[18px] text-[color:red]" onClick="">Hesabımı silmek istiyorum</button>
        </div>

    );
}