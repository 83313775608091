import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentAccount:{
        id:1,
        fullName:"Hatice Sudenas",
        avatar:"https://pbs.twimg.com/profile_images/1597192110800781314/uKMFImFR_bigger.png"
    },
}

const auth = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        _addAccount: (state, action) => {
            state.accounts.push(action.payload)
        },
        _removeAccount: (state, action) => {
            state.accounts = state.accounts.filter(c => c.id !== action.payload)
        },

    }
})
export const {_addAccount,_removeAccount}=auth.actions;
export default auth.reducer