import Button from "../../../components/button";
import SelectInputs from "../../../components/inputs/selectİnputs";
import { softwareLanguage, worldLanguage } from "../../../utils/consts";

export default function Language({ setLanguage, language, index }) {
    const handleRemoveInput = (index,e) => {
        e.preventDefault();
        const newList = language.filter((_, i) => i !== index);
        setLanguage(newList);
    };
    return (
        <div key={index} className="grid grid-cols-2 mt-3 gap-10 mb-20">
            <div>
                <SelectInputs size="normal">
                    <option selected>Yabancı Dil</option>
                    {
                        worldLanguage.map((item ,index)=> (
                            <option key={index} >{item}</option>
                        ))
                    }
                </SelectInputs>
            </div>
            <div>
                <div className="grid grid-cols-2 gap-10">
                    <SelectInputs size="normal">
                        <option selected>Seviye</option>
                        <option>Başlangıç</option>
                        <option>Orta Düzey</option>
                        <option>Orta-ileri Düzey</option>
                        <option>İleri Düzey</option>
                        <option>Anadil</option>
                    </SelectInputs>
                    <div>
                    <Button onClick={(e) => handleRemoveInput(index,e)} className="transition-colors" size="normal" variant="white-outline">Sil</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}