import Button from "../../../components/button";
import ClassicInputs from "../../../components/inputs/classicInputs";
import SelectInputs from "../../../components/inputs/selectİnputs";
import { disabledType } from "../../../utils/consts";

export default function Disabled({ index, disabled, setDisabled }) {
    const handleRemoveInput = (index, e) => {
        e.preventDefault();
        const newList = disabled.filter((_, i) => i !== index);
        setDisabled(newList);
    };
    return (
        <div key={index} className="grid grid-cols-4 mt-3 gap-4 mb-20">

            <div>
                <SelectInputs size="normal">
                    <option selected>Engel türü</option>
                    {
                        disabledType.map((item, index) => (
                            <option key={index} >{item}</option>
                        ))
                    }
                </SelectInputs>
            </div>
            <div className="relative">
                <div className="absolute -top-7">Engel Oranı</div>
                <ClassicInputs placeholder="0-100%" size="small" />
            </div>

            <div>
                <SelectInputs size="normal">
                    <option selected>Rapor</option>
                    <option>Var</option>
                    <option>Yok</option>
                </SelectInputs>
            </div>


            <div>
                <Button onClick={(e) => handleRemoveInput(index, e)} className="transition-colors" size="normal" variant="white-outline">Sil</Button>
            </div>


        </div>
    )
}