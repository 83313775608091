import Tab from "../../../components/Tab";
import StickyHeader from "../../../components/stick-header";
import FirstEvent from "./firstEvent";
import SecondEvent from "./secondEvent";

export default function Explanition() {
    return (
        <div className="mx-auto">
            <div className="m-12">
                <img src="https://coderspace-storage-prod.s3.eu-central-1.amazonaws.com/media/cache/5f/bd/5fbd44dce0fef14313095ce10fa9a03a.webp" className="h-[450px] w-full rounded-lg" />
                <div className="my-20 text-[32px] font-bold">KPMG IxT Olympics Innovation and Technology Challenge 2024</div>
                <div>
                    <Tab activeTab="for-you">
                        <StickyHeader>
                            <Tab.Items>
                                <Tab.Item id="for-you">
                                    Etkinlik Detayları
                                </Tab.Item>
                                <Tab.Item id="followings">
                                    Sıkça Sorulan Sorular
                                </Tab.Item>
                            </Tab.Items>
                        </StickyHeader>
                        <Tab.Content id="for-you">
                            <FirstEvent />
                        </Tab.Content>
                        <Tab.Content id="followings">
                            <SecondEvent />
                        </Tab.Content>
                    </Tab>
                </div>
            </div>
        </div>
    );
}