import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth";
import modal from "./modal";
import appearance from "./appearance";
import language from "./language";
import thunk from "redux-thunk";
const store =configureStore({
    reducer:{
        auth,
        modal,
        appearance,
        language
    },
    middleware: [thunk],

})
export default store;