import classNames from "classnames"
import { createElement } from "react"
import PropTypes from 'prop-types';
export default function ClassicInputs({size,className,children,...props}){
    return createElement('input',{
        className:classNames("outline-none bg-[color:var(--background-secondary)] border-2  border-[color:var(--background-third)] font-bold text-md rounded-lg focus:bg-[color:var(--background-primary)] block p-2.5 py-3",{
            "w-50": size==="small",
            "w-100": size==="normal",
            [className]:true
        }),
        ...props
    },children)
}
ClassicInputs.propTypes={
    size:PropTypes.oneOf(["small","normal"]),
    className:PropTypes.string
}
ClassicInputs.defaultProps={
    size:"normal",
}