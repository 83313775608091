import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home";
import NotFound from "../pages/notFound";
import MainLayout from "../layouts/main";
import Contact from "../pages/contact";
import CareerInfo from "../pages/careerInfo";
import University from "../pages/university";
import Talent from "../pages/talent";
import ExtraInformation from "../pages/extraInformation";
import SecondLayout from "../layouts/secondLayout";
import Tests from "../pages/tests";
import Activities from "../pages/activities";
import ThirdLayout from "../layouts/thirdLayout";
import Login from "../pages/login";
import Register from "../pages/register";
import ActivityDetail from "../pages/activityDetails";
import Certificates from "../pages/certificates";
import Settings from "../pages/settings";

const routes = createBrowserRouter([
    {
        path: "/",
        element: <ThirdLayout />,
        children:[
            {
                index: true,
                element: <Login/>
            },
            {
                path: "/register",
                element: <Register/>
            },
        ]
    },
    {
        path: "/main",
        element: <MainLayout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: "/main/iletisim-bilgileri",
                element: <Contact />
            },
            {
                path: "/main/kariyer-bilgileri",
                element: < CareerInfo />

            },
            {
                path: "/main/deneyim-universite-bilgileri",
                element: <University />

            },
            {
                path: "/main/yetenekler",
                element: <Talent />

            },
            {
                path: "/main/sertifikalar",
                element: <Certificates/>

            },
            {
                path: "/main/engelsiz",
                element: <ExtraInformation />

            },

            {
                path: "/main/ayarlar",
                element: <Settings />

            },
            {
                path: "*",
                element: <NotFound />

            }
        ]
    },
    {
        path: "/testler",
        element: <SecondLayout />,
        children:[
            {
                index: true,
                element: <Tests/>
            },
            {
                path: "/testler/etkinlikler",
                element: <Activities/>
            },
            {
                path: "/testler/etkinlikler/etkinlik-detayi",
                element: <ActivityDetail/>
            },
        ]
    },
    

])

export default routes;
/**import { useParams } from "react-router-dom"

export default function Profile(){
    const {slug}=useParams();
    return(
        
        <div>
            Profile - {slug}
        </div>
    )
} */