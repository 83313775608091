import { useState } from "react";
import Technology from "./technology";
import Language from "./language";
import Button from "../../components/button";


export default function Talent() {
    const [technology, setTechnology] = useState([""]);

    const handleAddInputTechnology = (e) => {
        e.preventDefault();
        setTechnology(prevTechnology => [...prevTechnology, '']);
    };


    const [language, setLanguage] = useState([""]);
    const handleAddInputLanguage = (e) => {
        e.preventDefault();
        setLanguage([...language, '']);
    };

    return (
        <form>
            <div className="p-12">
                <div className="pr-4 text-[20px] font-bold my-4">
                    Yetenek Bilgileri
                </div>
                <div className="h-px bg-[color:var(--background-third)]  w-[89%]" />
                <div className="flex justify-between items-center">
                    <div className="mt-10 mb-3 text-[23px]">Teknoloji (Yazılım Dili, Framework, Database)</div>
                    <button className="text-[23px] text-[color:var(--color-primary)] font-bold" onClick={handleAddInputTechnology}>Yeni Ekle + </button>
                </div>
                <div>
                    {
                        technology.map((input, index) => (
                            <Technology key={index} index={index} technology={technology} setTechnology={setTechnology} />
                        ))
                    }
                </div>

                <div className="flex justify-between items-center">
                    <div className="mt-10 mb-3 text-[23px]">Yabancı Dil</div>
                    <button className="text-[23px] text-[color:var(--color-primary)] font-bold" onClick={handleAddInputLanguage}>Yeni Ekle + </button>
                </div>
                <div>
                    {
                        language.map((input, index) => (
                            <Language index={index} language={language} setLanguage={setLanguage} />
                        ))
                    }
                </div>
            </div>
            <div className="justify-items-stretch grid">
                <div className="m-10 justify-self-end w-[390px]">
                    <Button size="normal" className="w-full">{`Kaydet > `}</Button>
                </div>
            </div>
        </form>
    )
}
