import { Disclosure } from "@headlessui/react";
import classNames from "classnames";

export default function Questions({title,description}){
    return(
        <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className={classNames("shadow-box rounded border-1 border-[color:var(--background-modal)] h-[52px] flex items-center justify-between w-full text-[18px] px-4 font-semibold hover:bg-[color:var(--background-secondary)] transition-colors my-3", { "rounded-b-lg": open })}>
                  {title}
                  <svg className={classNames({ "rotate-180 text-[color:var(--color-primary)]": open })} viewBox="0 0 24 24" width={18.75} height={18.75}>
                    <path
                      fill="currentColor"
                      d="M3.543 8.96l1.414-1.42L12 14.59l7.043-7.05 1.414 1.42L12 17.41 3.543 8.96z"
                    />
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <div className="flex w-full  px-4 mb-[80px]  gap-3 text-[17px] font-normal hover:bg-[color:var(--background-secondary)] transition-colors text-[color:var(--background-modal)]">
                    {description}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
    )
}