import Button from "../../components/button";
import IconInputs from "../../components/inputs/iconInputs";
import { Activity } from "../../mock";

export default function Tests() {
    return (
        <div>
            <div className="flex justify-between items-center my-4">
                <div className="mt-10 mb-3 mr-[500px] text-[35px] font-bold">Testler</div>
                <div className="flex-1 mt-3">
                    <IconInputs className="w-full" placeholder="Ara..." svg="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9 11.5C9 10.1193 10.1193 9 11.5 9C12.8807 9 14 10.1193 14 11.5C14 12.8807 12.8807 14 11.5 14C10.1193 14 9 12.8807 9 11.5ZM11.5 7C9.01472 7 7 9.01472 7 11.5C7 13.9853 9.01472 16 11.5 16C12.3805 16 13.202 15.7471 13.8957 15.31L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L15.31 13.8957C15.7471 13.202 16 12.3805 16 11.5C16 9.01472 13.9853 7 11.5 7Z" />
                </div>
            </div>

            <div className="grid grid-cols-3 mt-3 gap-10">
                {
                    Activity.map(item => (
                        <div className="max-w-sm border-2 border-[color:var(--background-third)] rounded-lg shadow ">
                            <div className="mx-3 text-center">
                                <div className="mt-3 mb-12">
                                    <div className="flex text-left w-full transition-colors items-center block group">
                                        <img src={item.img} className="w-30 h-30 rounded-full" />
                                        <div className="mx-3 flex-1">
                                            <h6 className="text-[22px]">{item.name}</h6>
                                            <div className="text-[color:var(--color-base-secondary)] text-[16px] my-2">{item.activityName}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-10">
                                    <div className="bg-[color:var(--background-secondary)] border-2 border-[color:var(--background-secondary)] py-3">
                                        <div className="pl-16">
                                            <svg viewBox="0 0 24 24" className="text-[color:var(--color-base-secondary)]" width={24} height={24}>
                                                <path
                                                    fill="currentColor"
                                                    d="M14.9523 6.2635L10.4523 18.2635L9.04784 17.7368L13.5478 5.73682L14.9523 6.2635ZM19.1894 12.0001L15.9698 8.78042L17.0304 7.71976L21.3108 12.0001L17.0304 16.2804L15.9698 15.2198L19.1894 12.0001ZM8.03032 15.2198L4.81065 12.0002L8.03032 8.78049L6.96966 7.71983L2.68933 12.0002L6.96966 16.2805L8.03032 15.2198Z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="text-[color:var(--color-base-secondary)] text-[16px]">Yazılım Dili</div>
                                        <div className="text-[17px] font-bold">Swift</div>
                                    </div>
                                    <div className="bg-[color:var(--background-secondary)] border-2 border-[color:var(--background-secondary)] py-3">
                                        <div className="pl-16">
                                            <svg viewBox="0 0 24 24" className="text-[color:var(--color-base-secondary)]" width={24} height={24}>
                                                <path
                                                    fill="currentColor"
                                                    d="M14.9523 6.2635L10.4523 18.2635L9.04784 17.7368L13.5478 5.73682L14.9523 6.2635ZM19.1894 12.0001L15.9698 8.78042L17.0304 7.71976L21.3108 12.0001L17.0304 16.2804L15.9698 15.2198L19.1894 12.0001ZM8.03032 15.2198L4.81065 12.0002L8.03032 8.78049L6.96966 7.71983L2.68933 12.0002L6.96966 16.2805L8.03032 15.2198Z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="text-[color:var(--color-base-secondary)] text-[16px]">Süre</div>
                                        <div className="text-[17px] font-bold">90 Dakika</div>
                                    </div>
                                </div>
                                <div className="h-15 my-6">
                                    <Button size="normal" className="w-full">{`Teste Git > `}</Button>
                                </div>

                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}