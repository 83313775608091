import { useEffect } from "react"
import { useAppearance } from "../../store/appearance/hooks"
import { useModal } from "../../store/modal/hooks"
import NavBar from "../main/navbar"
import { Outlet } from "react-router-dom"
import Modal from "../../modals"

export default function SecondLayout() {
    const modal = useModal()
    const appearance = useAppearance()

    useEffect(() => {
        document.documentElement.style.setProperty("--background-primary", appearance.backGroundColor.primary)
        document.documentElement.style.setProperty("--background-primary-alpha", appearance.backGroundColor.primaryAlpha)
        document.documentElement.style.setProperty("--background-secondary", appearance.backGroundColor.secondary)
        document.documentElement.style.setProperty("--background-third", appearance.backGroundColor.third)
        document.documentElement.style.setProperty("--background-modal", appearance.backGroundColor.modal)

        document.documentElement.style.setProperty("--color-primary", appearance.color.primary)
        document.documentElement.style.setProperty("--color-secondary", appearance.color.secondary)
        document.documentElement.style.setProperty("--color-base", appearance.color.base)
        document.documentElement.style.setProperty("--color-base-secondary", appearance.color.baseSecondary)

        document.documentElement.style.setProperty("--font-size", appearance.fontSize + "px")
    }, [appearance])

    return (
        <div>
            {
                modal && (<Modal />)
            }
            <NavBar />
            <div className="w-[1265px] mx-auto flex">
                <main className="flex-1 flex gap-[30px]">
                    <main className="flex-1 max-w-[2000px]">
                        <Outlet />
                    </main>
                </main>
            </div>
        </div>
    );
}