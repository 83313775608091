export default function Certificates(){
    return(
        <div className="p-12">
        <div className="pr-4 text-[20px] font-bold my-4">
          Sertifikaların
        </div>
        <div className="h-px bg-[color:var(--background-third)]  w-[89%]" />
        <div className="mt-4 font-normal text-[17px]">Etkinliklerden kazanmış olduğun sertifikaların burada listelenecektir.</div>
      </div>
    )
}