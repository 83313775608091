import Questions from "./questions";
import Tab from "../../components/Tab";
import StickyHeader from "../../components/stick-header";
import Event from "./event";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
export default function Home() {
  const selectedLanguages = useSelector((state) => state.language.selectedLanguages);
  return (
    <div>

      <div className="p-12">
        <div className="pr-4 text-[20px] font-bold my-4 flex justify-between items-center">
          Kariyer Özeti
          <NavLink to="/main/kariyer-bilgileri" className="text-[18px] text-[color:var(--color-primary)]" >{`Düzenle`}</NavLink>
        </div>
        <div className="h-px bg-[color:var(--background-third)]  w-[97%]" />
        {
          selectedLanguages.length>0 &&
          <>
            <div className="text-[19px] my-4">Pozisyon Tercihi</div>
            <ul>
              {selectedLanguages.map((language, index) => (
                <li className="my-3 text-[color:var(--color-base-secondary)] text-[17px]" key={index}>{language.section}  <label className="text-[color:var(--color-secondary)]"> ( {language.language} ) </label></li>
              ))}
            </ul>
          </>
        }

      </div>



      <div className="p-12">
        <div className="pr-4 text-[20px] font-bold">
          Davetlerin
        </div>
        <div className="h-px bg-[color:var(--background-third)]  w-[89%]" />
        <div className="mt-4 font-normal text-[17px]">Profilindeki (*) yıldızlı  alanları tamamladıktan sonra sana özel iş tekliflerini alabilirsin.</div>
      </div>

      <div className="p-12">
        <div className="pr-4 text-[20px] font-normal my-4 flex items-center">
          <svg viewBox="0 0 24 24" width={24} height={24} className="mr-2">
            <path
              fill="currentColor"
              d=" M20 2H4c-1.103 0-2 .894-2 1.992v12.016C2 17.106 2.897 18 4 18h3v4l6.351-4H20c1.103 0 2-.894 2-1.992V3.992A1.998 1.998 0 0 0 20 2z"
            />
          </svg>
          Sıkça Sorulan Sorular
        </div>
        <div className="h-px bg-[color:var(--background-third)]  w-[89%]" />
        <div className="mt-4">
          <Questions title="Görüşme Daveti Nedir ?" description="orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release" />
          <Questions title="Neden bu görüşme davetini aldım ?" description="orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release" />
          <Questions title="Daveti kabul edersem ne olacak ?" description="orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release" />
        </div>

        <div>
          <Tab activeTab="for-you">
            <StickyHeader>
              <Tab.Items>
                <Tab.Item id="for-you">
                  Etkinlikler
                </Tab.Item>
                <Tab.Item id="followings">
                  Geçmiş Etkinliklerin
                </Tab.Item>
              </Tab.Items>
            </StickyHeader>
            <Tab.Content id="for-you">
              <Event />
            </Tab.Content>
            <Tab.Content id="followings">
              2.Tab
            </Tab.Content>
          </Tab>
        </div>
      </div>
    </div>
  );
}