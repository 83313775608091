import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { navBarMenu } from "../../../utils/consts";
import Account from "./account";
import { setModal } from "../../../store/modal/action";

function NavBar(args) {
    return (
        <nav class="flex justify-around bg-[color:var(--background-primary)] dark:bg-gray-900 border-1 shadow-box border-b border-[color:var(--background-third)]">
            <div class="max-w-screen-xl flex flex-wrap items-center justify-left  px-5 ">
                <a href="https://flowbite.com/" class="flex items-center space-x-1 rtl:space-x-reverse">
                    <img src="https://flowbite.com/docs/images/logo.svg" class="h-8" alt="Flowbite Logo" />
                    <span class="self-center text-4xl font-semibold whitespace-nowrap dark:text-white">Talenteer</span>
                </a>

                <div class="ml-20 hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul class="font-normal text-xl flex flex-col p-4 md:p-0 mt-4  rounded-lg  md:flex-row md:space-x-10 rtl:space-x-reverse md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                        {
                            navBarMenu.map((menu, index) => (
                                <NavLink to={typeof menu.path === "function" ? menu.path() : menu.path}>
                                    <div className="block px-3  rounded md:bg-transparent  md:p-0 md:hover:text-[color:var(--color-primary)]" >{menu.title}</div>
                                </NavLink>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className="flex">
                <div className="mr-12 my-auto rounded-full bg-[color:var(--color-primary)] hover:bg-[color:var(--color-secondary)]">
                    <NavLink onClick={() => setModal("appearance")} className="w-full flex items-center px-3 h-11 gap-3 transition-colors">
                        <svg viewBox="0 0 21 21" width={18.75} height={18.75}>
                            <path
                                fill="currentColor"
                                d="M21,15V2a1,1,0,0,0-1-1H12a1,1,0,0,0-.929.629L10,4.308,8.929,1.629A1,1,0,0,0,8,1H4A1,1,0,0,0,3,2V15a2,2,0,0,0,2,2H9v3a3,3,0,0,0,3,3,3.054,3.054,0,0,0,3-3.107V17h4A2,2,0,0,0,21,15ZM5,3H7.323L9.071,7.371a1,1,0,0,0,1.858,0L12.677,3H19v8H5Zm9,12a1,1,0,0,0-1,1v3.893a1.083,1.083,0,0,1-.825,1.092A1,1,0,0,1,11,20V16a1,1,0,0,0-1-1H5V13H19v2Z"
                            />
                        </svg>
                    </NavLink>
                </div>
                <div ><Account /></div>
            </div>
        </nav>

    );
}

export default NavBar;

/**
 *  <NavLink>
                           {({ isActive }) => (
                                <a href="#" className={classNames("block py-2 px-3 text-[color:var(--backGroundColor-modal)] rounded md:bg-transparent  md:p-0 md:hover:text-[color:var(--color-primary)]", { "font-bold text-[color:var(--color-primary)]": isActive })} aria-current="page">Home</a>
                            )
                            }
                        </NavLink>
                        <NavLink>
                        {({ isActive }) => (
                                <a href="#" className={classNames("block py-2 px-3 text-[color:var(--backGroundColor-modal)] rounded md:bg-transparent  md:p-0 md:hover:text-[color:var(--color-primary)]", { "font-bold text-[color:var(--color-primary)]": isActive })} aria-current="page">Home</a>
                            )
                            }
                            <a href="#" class="block py-2 px-3 text-[color:var(--backGroundColor-modal)] rounded  md:border-0 md:hover:text-[color:var(--color-primary)] md:p-0">About</a>
                        </NavLink>
                        <NavLink>
                            <a href="#" class="block py-2 px-3 text-[color:var(--backGroundColor-modal)] rounded  md:border-0 md:hover:text-[color:var(--color-primary)] md:p-0">Services</a>
                        </NavLink>
                        <NavLink>
                            <a href="#" class="block py-2 px-3 text-[color:var(--backGroundColor-modal)] rounded  md:border-0 md:hover:text-[color:var(--color-primary)] md:p-0">Pricing</a>
                        </NavLink>
                        <NavLink>
                            <a href="#" class="block py-2 px-3 text-[color:var(--backGroundColor-modal)] rounded  md:border-0 md:hover:text-[color:var(--color-primary)] md:p-0">Contact</a>
                        </NavLink>
 */