import Explanition from "./explanition";
import PartipicationDetail from "./partipicationDetail";

export default function ActivityDetail() {
    return (
        <div className="w-[1265px] mx-auto flex">
                <PartipicationDetail className="w-full"/>
                <main className="flex-1 flex gap-[30px]">
                    <main className="flex-1 max-w-[900px] border-l border-[color:var(--background-third)]">
                        <Explanition/>
                    </main>
                </main>
            </div>
    );
}